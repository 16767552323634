<template>
    <footer class="cool-footer">
      <div class="footer-content">
        <div class="footer-partners">
          <img src="@/assets/images/logos/delphinus-logo-popup.png">
          <img src="@/assets/images/logos/rhm-logo-positiv.png" style="background-color: white; padding: 0 5px 0 5px">
          <img src="@/assets/images/logos/codevance_logo_positiv.svg" style="background-color: white; padding: 0 5px 0 5px">
          <img src="@/assets/images/logos/OC_Logo_Hero.png">
          <img src="@/assets/images/logos/oceanwildlife-logo.png">
          <img src="@/assets/images/logos/Logo-white-IRS.svg">
        </div>
        <div class="footer-social">
          <router-link to="/dataprivacy">Data Privacy</router-link>
          <router-link to="/">Impress</router-link>
          <router-link to="/projects">Projects</router-link>
          <router-link to="/skills">Skills</router-link>
          <button @click="triggerPopup">Contact</button>
          <Popup ref="popupRef" />
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  import Popup from "@/components/popup/PopUp.vue";
  export default {
    name: "CoolFooter",
    methods: {
      triggerPopup() {
        this.$refs.popupRef.openPopup();
      },
    },
    components: {
      Popup,
    },
  };
  </script>
  
  <style scoped>
  .cool-footer {
    background-color: rgb(32, 32, 35);
    color: white;
    padding: 40px;
    text-align: center;
    font-size: 12px;
    position: sticky;
    bottom: 0;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo {
    letter-spacing: 10px;
    margin: 16px 24px;
    font-weight: 500;
    font-family: var(--secondaryFont);
    font-size: 40px;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    font-weight: 500;
  }

  .footer-social{
    margin-top: 20px;
  }
  
  .footer-social a {
    color: white;
    margin-right: 10px;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .footer-partners{
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    color: white;
    overflow: auto;
  }

  .footer-partners img{
    max-width: 120px;
    max-height: 50px;
    width: 100%;
    object-fit: contain;
    object-position: left;
  }
  
  button{
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: white;
    margin-right: 10px;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    cursor: pointer;
  }

@media screen and (max-width: 500px) {

.footer-content{
  flex-direction: column;
}

.footer-partners img{
  object-position: center;
}

.footer-social{
  flex-direction: column;
    display: flex;
    gap: 16px;
}

}
  </style>